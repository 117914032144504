import { createActionGroup, props } from '@ngrx/store';

export const dataCalendarApiActions = createActionGroup({
  source: 'DataCalendar API',
  events: {
    'Load DataCalendar data': props<{ start: string, end: string }>(),
    'Load DataCalendar data Success': props<{ production: string[], crude: string[], program: string[], planning: string[], prices: string[] }>(),
    'Load DataCalendar data Failure': props<{ error: string }>()
  },
});
