import { createFeature, createReducer, on } from "@ngrx/store";
import { dataCalendarApiActions } from "./actions";

export interface State {
  production: string[];
  crude: string[];
  program: string[];
  planning: string[];
  prices: string[];
  loading: boolean;
  error: string;
}

export const initialState: State = {
  production: [],
  crude: [],
  program: [],
  planning: [],
  prices: [],
  loading: false,
  error: '',
};

export const dataCalendarFeature = createFeature({
  name: 'dataCalendar',
  reducer: createReducer(
    initialState,
    on(dataCalendarApiActions.loadDataCalendarData, (state) => ({
      ...state,
      loading: true,
    })),
    on(
      dataCalendarApiActions.loadDataCalendarDataSuccess,
      (state, { production, crude, program, planning, prices }) => {
        return ({
          ...state,
          production: [...production],
          crude: [...crude],
          program: [...program],
          planning: [...planning],
          prices: [...prices],
          loading: false,
        })
      }
    ),
    on(dataCalendarApiActions.loadDataCalendarDataFailure, (state) => ({
      ...state,
      loading: false,
      error: 'Failed to load data calendar data',
    }))
  ),
});



export const {
  selectLoading,
  selectCrude,
  selectPrices,
  selectProduction,
  selectProgram,
  selectPlanning,
} = dataCalendarFeature;
